<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-if="[1, 2, 3].includes(form.list_s)">
    <Crumbs>
      <el-breadcrumb-item slot="after"> 绩效工时详情 </el-breadcrumb-item>
    </Crumbs>

    <div class="fullScreenMain" v-loading="loading" id="Scrollbar">
      <div class="fullScreenMainCon">
        <!-- 第一行 ：填报月份回显 -->
        <!-- <el-row>
          <el-col :span="24">
            <div class="main_heading">填报月份：{{ form.month }}</div>
          </el-col>
        </el-row> -->
        <!-- 第二行 ： 项目名称 -->
        <!-- <el-row>
          <el-col :span="24">
            <div class="main_heading">项目名称：{{ form.projectName }}</div>
          </el-col>
        </el-row> -->
        <!-- 第三行 ： 项目描述与填报时间 -->
        <!-- <el-row :gutter="20">
          <el-col :span="8">
            <div class="sub_heading">业主单位：{{ form.unit }}</div>
          </el-col>
          <el-col :span="8">
            <div class="sub_heading">实施地点：{{ form.implementSite }}</div>
          </el-col>
          <el-col :span="8" v-if="form.taskDate">
            <div class="sub_heading">
              填报时间：{{ new Date(form.taskDate).format('yyyy-MM-dd hh:mm:ss') }}
            </div>
          </el-col>
        </el-row> -->

        <!-- 描述列表 -->
        <el-descriptions :column="4" border direction="vertical" style="margin-top: 10px">
          <el-descriptions-item label="填报月份" content-class-name="main_heading">{{
            form.monthYear
          }}</el-descriptions-item>
          <el-descriptions-item label="项目名称" content-class-name="main_heading" :span="3">{{
            form.projectName
          }}</el-descriptions-item>
          <el-descriptions-item label="业主单位">{{ form.customersUnit }}</el-descriptions-item>
          <el-descriptions-item label="实施地点">{{ form.implementSite }} </el-descriptions-item>
          <el-descriptions-item label="项目经理" v-if="options.list_s == 3"
            >{{ form.createName }}
          </el-descriptions-item>
          <el-descriptions-item label="填报时间" v-if="form.taskDate">{{
            new Date(form.taskDate).format('yyyy-MM-dd HH:mm:ss')
          }}</el-descriptions-item>
        </el-descriptions>

        <!-- 表单数据 -->
        <el-form
          class="column3 form_box table"
          label-position="top"
          style="width: 100%; margin-top: 10px">
          <el-table :data="form.detailList" id="table" show-summary :summary-method="getSummaries">
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="80px"></el-table-column>
            <el-table-column
              align="center"
              prop="staffName"
              label="成员"
              :show-overflow-tooltip="false"></el-table-column>
            <el-table-column
              align="center"
              prop="deptName"
              label="部门"
              :show-overflow-tooltip="false"></el-table-column>
            <el-table-column
              align="center"
              prop="sumHour"
              label="填报工时"
              :show-overflow-tooltip="false"></el-table-column>
            <el-table-column
              align="center"
              prop="sumAuditHour"
              label="审核工时"
              :show-overflow-tooltip="false"></el-table-column>
            <el-table-column
              align="center"
              prop="performanceHour"
              label="绩效工时"
              :show-overflow-tooltip="false"
              label-class-name="performance_title">
              <template slot-scope="scope">
                <el-input
                  v-if="form.list_s !== 3"
                  onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                  placeholder="请输入"
                  v-model="scope.row.performanceHour">
                </el-input>
                <span class="performance_title" v-else>{{ scope.row.performanceHour }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="title" align="center" width="100" fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  class="text_Details_Bgc"
                  @click.stop="showDialog(scope.row)"
                  >查看明细</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
      <div class="fullScreenOperation">
        <el-button
          v-if="form.list_s != 3"
          type="success"
          class="teal_bg"
          :loading="loading"
          @click="save">
          提交
        </el-button>
        <!-- 当list_s != 3表示不是详情按钮进入，页面内容可编辑，当status != 20表示该记录未提交可保存 -->
        <el-button
          v-if="form.list_s != 3 && form.status != 20"
          type="success"
          :loading="loading"
          @click="tempSave">
          {{ form.id ? '保存' : '暂存' }}
        </el-button>
        <el-button type="info" @click="re">返回</el-button>
      </div>
    </div>

    <el-dialog
      title="员工工时明细"
      :visible.sync="ishourDetailDialogShow"
      append-to-body
      width="75%">
      <el-row :gutter="20" style="margin-bottom: 10px">
        <el-col :span="3"
          ><div>姓名：{{ dialogData.staffName }}</div></el-col
        >
        <el-col :span="3"
          ><div>部门：{{ dialogData.deptName }}</div></el-col
        >
        <el-col :span="3"
          ><div>月份：{{ dialogData.momthYear }}</div></el-col
        >
        <el-col :span="15"
          ><div>项目：{{ dialogData.projectName }}</div></el-col
        >
      </el-row>

      <el-tabs tab-position="top" v-model="activeName" @tab-click="handleTabsClick">
        <el-tab-pane label="上旬" name="first"></el-tab-pane>
        <el-tab-pane label="中旬" name="middle"></el-tab-pane>
        <el-tab-pane label="下旬" name="last"></el-tab-pane>
      </el-tabs>
      <el-table
        border
        stripe
        :data="dialogData.hourDetailData"
        v-loading="loading"
        row-key="id"
        height="550">
        <el-table-column align="center" type="index" label="序号"> </el-table-column>
        <el-table-column prop="manhourDate" label="工时日期" width="100" align="center">
        </el-table-column>
        <el-table-column prop="hour" label="填报工时" width="80" align="center"> </el-table-column>
        <el-table-column prop="auditHour" label="审核工时" width="80" align="center">
        </el-table-column>
        <el-table-column label="工作内容">
          <template slot-scope="scope">
            <div class="work-content">
              {{ scope.row.workContent }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="padding: 20px 20px 5px 20px; text-align: right"
        :total="dialogQuery.total"
        layout="total">
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { encrypt, decrypt } from '@/util/jsencrypt'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  inject: ['re'],
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      store_showEditDialog: state => state.performanceHour.store_showEditDialog,
      store_detail: state => state.performanceHour.store_detail,
    }),
  },
  data() {
    return {
      showEditDialog: false,
      loading: false,
      submitLoading: false,
      ishourDetailDialogShow: false, //员工工时明细对话框展示
      form: {},
      rendertable: false,
      // 员工工时明细对话框数据对话框数据
      dialogData: {
        hourDetailData: [],
      },
      dialogQuery: {
        total: 0,
        userName: '', //员工姓名
        projectId: '', //项目id
        beginDate: '',
        endDate: '',
      },
      activeName: 'first', //选中上旬、中旬、下旬的值
      loading: false, //加载数据
    }
  },
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.init()
        }
      },
    },
  },
  methods: {
    ...mapMutations('performanceHour', ['changeShowEditDialog', 'changeDetail']),
    init() {
      // 发起请求获取页面内容
      if (this.options.id) {
        // 有id表示该绩效工时已填报
        this.getFormDataById()
      } else {
        // 该绩效工时未填报
        this.getFormDataWithoutId()
      }
    },

    getFormDataById() {
      this.$api.hour
        .getManhourPerformanceDetailById(this.options.id)
        .then(res => {
          this.form = res.data
          this.form.list_s = this.options.list_s
          this.form.status = this.options.status
          this.form.taskDate = this.options.taskDate
          // 将当前页面数据全部转存到vuex中
          this.changeDetail(this.form)
        })
        .catch(err => {
          console.log(err)
        })
    },

    getFormDataWithoutId() {
      this.$api.hour
        .getManhourPerformanceDetailByProjectId({
          monthYear: this.options.monthYear,
          projectId: this.options.projectId,
        })
        .then(res => {
          this.form = res.data
          this.form.list_s = this.options.list_s
          // 将当前页面数据全部转存到vuex中
          this.changeDetail(this.form)
        })
        .catch(err => {
          console.log(err)
        })
    },

    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index >= 3) {
          //index>=3后的列进行求和
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] = sums[index].toFixed(1)
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        } else {
          sums[index] = '--'
        }
      })
      return sums //最后返回合计行的数据
    },

    // gotoUserHour(value) {
    //   // 获取当前月份第一天与最后一天时间戳
    //   const { firstDay, lastDay } = this.getTimeStampOfMonth(this.form.monthYear)
    //   const createdDate = []
    //   createdDate.push(new Date(firstDay))
    //   createdDate.push(new Date(lastDay))
    //   this.$router.push({
    //     path: '/hour/byUser',
    //     query: {
    //       userName: value.staffName,
    //       projectId: encrypt(this.form.projectId),
    //       createdDate: JSON.stringify(createdDate),
    //       empty: 'empty',
    //     },
    //   })
    // },

    pageChangeHandler(val) {
      this.dialogQuery.pageNow = val
    },

    handleSizeChange(val) {
      this.dialogQuery.pageSize = val
      this.dialogQuery.pageNow = 1
    },

    getTimeStampOfMonth(month) {
      // 创建一个日期对象，指定月份为传入的参数
      const date = new Date(month)

      // 获取该月份的第一天
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
      const firstDayTimeStamp = firstDay.getTime()

      // 获取该月份的下个月的第一天
      const nextMonth = date.getMonth() + 1
      const nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1)
      const lastDayTimeStamp = nextMonthFirstDay.getTime() - 1

      // 返回第一天和最后一天的时间戳
      return {
        firstDay: firstDayTimeStamp,
        lastDay: lastDayTimeStamp,
      }
    },

    // 点击查看明细按钮，显示对话框，重置表单内容
    showDialog(value) {
      // 重置当前请求参数
      this.dialogQuery = {
        total: 0,
        userName: '',
        projectId: '',
        beginDate: '',
        endDate: '',
      }
      // 获取当前行数据，成员信息
      this.dialogData = value
      // 获取当前页面数据，项目信息，月份信息
      this.activeName = 'first'
      this.dialogData.projectName = this.form.projectName
      this.dialogData.projectId = this.form.projectId
      this.dialogData.momthYear = this.form.monthYear
      const { firstDay, lastDay } = this.getTimeStampOfMonth(this.form.monthYear)
      const { eleventhDay, twentyFirstDay } = this.getQueryTimeStamp()
      // 请求参数赋值
      this.dialogQuery.userName = this.dialogData.staffName
      this.dialogQuery.projectId = this.dialogData.projectId
      this.dialogQuery.beginDate = firstDay
      this.dialogQuery.endDate = eleventhDay - 1
      this.getHourDetailData()
    },

    // 获取该员工当月当项目工时明细
    getHourDetailData() {
      this.loading = true
      this.dialogData.hourDetailData = []
      this.$api.hour
        .getAllMahhoursByUser(this.dialogQuery)
        .then(res => {
          this.loading = false
          if (res.data == null) {
            this.dialogData.hourDetailData = []
          } else {
            let arr = []
            for (let i = 0; i < res.data.records.length; i++) {
              const e = res.data.records[i]
              e.manhourDate = new Date(e.manhourDate).format('yyyy-MM-dd')
              arr.push(e)
            }
            this.dialogData.hourDetailData = arr
            this.dialogQuery.total = res.data.total
            this.ishourDetailDialogShow = true
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },

    handleTabsClick() {
      // 重置当前请求参数
      this.dialogQuery = {
        total: 0,
        userName: '',
        projectId: '',
        beginDate: '',
        endDate: '',
      }
      this.dialogQuery.userName = this.dialogData.staffName
      this.dialogQuery.projectId = this.dialogData.projectId
      const { firstDay, lastDay } = this.getTimeStampOfMonth(this.form.monthYear)
      const { eleventhDay, twentyFirstDay } = this.getQueryTimeStamp()
      if (this.activeName == 'first') {
        // 是上旬，获取当月第1天和第10天时间戳
        this.dialogQuery.beginDate = firstDay
        this.dialogQuery.endDate = eleventhDay - 1
      } else if (this.activeName == 'middle') {
        // 是中旬，获取第11天和第20天时间戳
        this.dialogQuery.beginDate = eleventhDay
        this.dialogQuery.endDate = twentyFirstDay - 1
      } else if (this.activeName == 'last') {
        this.dialogQuery.beginDate = twentyFirstDay
        this.dialogQuery.endDate = lastDay
      }
      this.getHourDetailData()
    },

    // 获取当月第11日与21日时间戳用于发送请求
    getQueryTimeStamp() {
      // firstDay是第一天时间戳，lastDay是最后一天时间戳，eleventhDay是11号时间戳，twentyFirstDay是21号时间戳
      const { firstDay, lastDay } = this.getTimeStampOfMonth(this.form.monthYear)
      const eleventh = new Date(firstDay)
      eleventh.setDate(eleventh.getDate() + 10)
      const eleventhDay = eleventh.getTime()
      const twentyFirst = new Date(firstDay)
      twentyFirst.setDate(twentyFirst.getDate() + 20)
      const twentyFirstDay = twentyFirst.getTime()
      return {
        eleventhDay: eleventhDay,
        twentyFirstDay: twentyFirstDay,
      }
    },

    tempSave() {
      let cond = {
        monthYear: this.form.monthYear,
        projectId: this.form.projectId,
        detailList: this.form.detailList,
      }
      this.editDialogLoading = true
      // 如果有id且当前状态是暂存，则走暂存编辑接口
      if (this.form.id) {
        cond.id = this.form.id
        this.$api.hour
          .editManhourPerformance(cond)
          .then(res => {
            this.editDialogLoading = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            console.log('错误：', err)
          })
      } else {
        this.$api.hour
          .tempAddManhourPerformance(cond)
          .then(res => {
            this.editDialogLoading = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            console.log('错误：', err)
          })
      }
    },

    save() {
      if (this.form.detailList) {
        const findResult = this.form.detailList.find(item => !item.performanceHour)
        if (findResult) {
          // 绩效工时未填写，弹框提示
          this.$confirm('页面中存在成员绩效工时未填写，请确认是否提交？', '提示', {
            confirmButtonText: '提交',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              this.saveFunction()
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消',
              })
            })
        } else {
          // 绩效工时全部填写，直接执行提交方法
          this.saveFunction()
        }
      }
    },

    // 执行提交方法
    saveFunction() {
      let cond = {
        monthYear: this.form.monthYear,
        projectId: this.form.projectId,
        detailList: this.form.detailList,
      }
      this.editDialogLoading = true
      if (this.form.id) {
        cond.id = this.form.id
      }
      console.log('提交')
      this.$api.hour
        .undeterminedSubmitManhourPerformance(cond)
        .then(res => {
          this.editDialogLoading = false
          this.showEditDialog = false
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          console.log('错误：', err)
        })
    },
  },
}
</script>

<style lang="scss">
.main_heading {
  padding-top: 10px;
  font-size: 24px;
  font-weight: bold !important;
  font-family: 'PingFang SC' !important;
  color: #303133 !important;
}
.sub_heading {
  padding-top: 10px;
  font-size: 16px;
  font-family: 'PingFang SC';
  color: #909399;
}

.performance_title {
  color: red;
}
.work-content {
  height: auto;
  word-wrap: break-word;
  overflow: auto;
  white-space: pre-wrap;
}
</style>
